/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyACP5a7jUS-yop8BYkjtKvCUmIXxXiVMpE",
  "appId": "1:679537571450:web:113f6849070a8c53596dc3",
  "authDomain": "schooldog-i-jeffdavis-ga.firebaseapp.com",
  "measurementId": "G-750YY36531",
  "messagingSenderId": "679537571450",
  "project": "schooldog-i-jeffdavis-ga",
  "projectId": "schooldog-i-jeffdavis-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-jeffdavis-ga.appspot.com"
}
